<template>
	<div class="home">
		<el-row :gutter="40">
			<el-col :span="6">
				<el-card shadow="hover" class="py16">
					<p class="fs16">今日总订单数</p>
					<p class="bold6 fs26 mt10 csub">{{order.todayOrder}}</p>
				</el-card>
			</el-col>
			<el-col :span="6">
				<el-card shadow="hover" class="py16">
					<p class="fs16">待发货订单数</p>
					<p class="bold6 fs26 mt10 csub">{{order.todayWait}}</p>
				</el-card>
			</el-col>
			<el-col :span="6">
				<el-card shadow="hover" class="py16">
					<p class="fs16">已发货订单数</p>
					<p class="bold6 fs26 mt10 csub">{{order.todaySend}}</p>
				</el-card>
			</el-col>
			<el-col :span="6">
				<el-card shadow="hover" class="py16">
					<p class="fs16">未发货总订单数</p>
					<p class="bold6 fs26 mt10 csub">{{order.totalWait}}</p>
				</el-card>
			</el-col>
		</el-row>
		<div class="fs20 bold6 aliL mt40">订单趋势图</div>
		<div>
			<div id="myChart" :style="{ width: '100%', height: '500px' }"></div>
		</div>

	</div>
</template>

<script>
	import * as echarts from 'echarts';
	import api from '@/config/api'
	
	export default {
		data() {
			return {
				optionLine: {},
				order:{},

			};
		},
		mounted() { 
			this.getEchartLine();
			this.getIndex()
		},
		methods: {
			async getIndex(){
				const res = await api.index();
				if(res.code == 1){

					this.order= res.data;
				}
			},
			getEchartLine() {
				// let myChart = echarts.init(document.getElementById("myChart"));
				// // 绘制图表
				// this.optionLine = {
				// 	xAxis: {
				// 		type: 'category',
				// 		boundaryGap: false,
				// 		data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
				// 	  },
				// 	yAxis: {
				// 		type: 'value'
				// 	},
				// 	series: [
				// 		{
				// 		  data: [820, 932, 901, 934, 1290, 1330, 1320],
				// 		  type: 'line',
				// 		  areaStyle: {}
				// 		}
				// 	]
				// };
				// this.optionLine && myChart.setOption(this.optionLine);
			},


		}
	}
</script>

<style lang="scss" scoped>
.chart {
  height: 400px;
}
</style>
